import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    component: Home,
    children : [
      {
        path: "",
        name: "Home",
        component: () => import('@/views/Index.vue')
      },      
      {
        path: "about",
        name: "About",
        component: () => import('@/views/About.vue')
      },
      {
        path: "projects/:project",
        name: "Project",
        component: () => import('@/views/Project.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
