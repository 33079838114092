import axios from 'axios'

export default {
    install: (app, options) => {
        var ax = axios.create({
            baseURL: options.baseUrl,
            headers: {
                Authorization: options.token ? options.token : '',
            }
        })
        app.config.globalProperties.$query = (query) => {
            return ax.post("", query)
        }
    }
}