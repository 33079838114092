import { createStore } from 'vuex'
import LazyLoad from "vanilla-lazyload"

export default createStore({
  state: {
    projects: [],
    currentProject: {},
    info: {
      title: "",
      about: "",
      seo: {}
    },
    about: {},
    lazy: new LazyLoad()
  },
  mutations: {
    setInfo(state, info) {
      state.info = info;
    },
    setProjects(state, projects) {
      state.projects = projects
    },
    setAbout(state, about) {
      state.about = about
    },
    unsetCurrentProject(state) {
      state.currentProject = {
        loaded: false
      }
    },
    setCurrent(state, project) {
      state.currentProject = {
        ...state.project,
        ...project
      }
    }
  },
  actions: {
    getCurrentProject({ state }, slug) {
      if (state.projects.length) {
        return state.projects.find(p => {
          return p.slug == slug
        })
      }
      else return false;
    }
  },
  modules: {
  }
})
