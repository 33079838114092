<template>
    <router-link :to="{ name: 'Project', params: { project: project.slug } }"
                 class="projectLink">
        <TimeMachine :images="images"
                     :sizes="sizes"
                     :aspect="1" />
        <div class="projectText">
            <div class="projectTitle">
                {{ project.title }}
            </div>
        </div>
    </router-link>
</template>

<style>
a.projectLink,
a.projectLink .imageContainer {
    text-decoration: none;
    color: black;
    cursor: pointer !important;
}
</style>

<style lang="scss" scoped>
a {
    text-decoration: none;
    color: black;
    cursor: pointer !important;
    transform: translateY(0px);
    transition: transform 300ms;


}

@media screen and (hover: hover) {
    a:hover {
        // transform: translateY(-30px);
    }
}

.projectText {
    display: flex;

    .projectTitle {
        flex-grow: 2;
        text-align: center;
        min-width: 0;
    }
}


@media screen and (max-width: (4*$minGap + 3*$imageWidth)) {
    .projectText {
        .projectTitle {
            text-align: center;
        }

        .projectYear {
            display: none;
        }
    }
}
</style>
<script>
import Image from './Image.vue';
import TimeMachine from './TimeMachine.vue';
export default {
    components: { Image, TimeMachine },
    props: ["project", "sizes"],
    computed: {
        images() {
            var imgs = this.project.images.map(p => {
                return p.imagedata
            })
            imgs[0] = this.project.seo.image;
            return imgs
        }
    }
}
</script>