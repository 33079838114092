import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import axios from './plugins/axios'

console.log("site env :"+process.env.VUE_APP_SITE_ENV)

createApp(App).use(store).use(router).use(axios, {
    token: "Basic YXBpX2FjY2Vzc0BrbG9uLnh5ejp0ZXN0dGVzdA==",
    baseUrl: process.env.NODE_ENV=="production"?"https://www.raphaelschoen.ch/api/api/query":"https://www.raphaelschoen.ch/api/api/query"
}).mount('#app')
