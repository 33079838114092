<template>
  <router-view />
</template>
<script>
export default {
  computed: {
    environment() {
      return process.env.NODE_ENV
    }
  },
  mounted() {
    console.log("environment : " + this.environment)
    if (document.querySelector("#siteInfo")) {
      this.$store.commit("setInfo", JSON.parse(document.querySelector("#siteInfo").innerHTML))
    }
    else {
      this.$query({
        "query": "site",
        "select": {
          "title": true,
          "seo": true,
          "about": "site.about.kt"
        }
      }).then(r => {
        this.$store.commit("setInfo", r.data.result)
        // console.log(r.data.result)
      })
    }
  },
  computed: {
    routename() {
      return this.$route.name
    }
  },
  watch: {
    routename: {
      handler() {
        if (this.routename == "Home") {
          document.documentElement.style.overflow = "auto"
          document.documentElement.style.position = "static"
          document.body.style.overflow = "auto"
          document.body.style.position = "static"

        }
        else {
          document.documentElement.style.overflow = "hidden"
          document.documentElement.style.position = "fixed"
          document.body.style.overflow = "hidden"
          document.body.style.position = "fixed"
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
@font-face {
  font-family: "Swiss Intl Mono";
  src: url(./styles/SuisseIntlMono-Regular.woff);
}

html,
body {
  background-color: #F4F4F4;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  -webkit-text-size-adjust: none;
}

body {
  scrollbar-gutter: stable both-edges;
}

#app {
  font-family: "Swiss Intl Mono";
  font-size: $fontsize;
}

#nav {
  padding: 30px;

}
</style>
