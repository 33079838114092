<template>
    <img :class="{ lazy: !superlazy }"
         :width="img.width"
         :height="img.height"
         :src="gensvg"
         :data-src="img.url"
         :data-srcset="img.srcset"
         :data-sizes="sizes"
         :alt="img.alt"
         :sizes="sizes" />
</template>

<script>
export default {
    props: ["img", "sizes", "superlazy"],
    data() {
        return {
            loaded: false
        }
    },
    computed: {
        gensvg() {
            // if (this.superlazy == true) {
            //     return this.img.url;
            // }
            // else {
            //     return `data:image/svg+xml;utf8,
            // <svg xmlns='http://www.w3.org/2000/svg' style="background-color:lightgrey" viewBox='0 0 ${this.img.width} ${this.img.height}' height='${this.img.height}' width='${this.img.width}'>
            //     </svg>`;
            // }
            return this.img.url;

        }
    },
    watch: {
        superlazy: {
            handler() {
                if (this.superlazy == false) {
                    this.$nextTick(() => {
                        // console.log("updating")
                        this.$store.state.lazy.update()
                    })
                }
            },
            immediate: true
        },
        img: {
            deep: true, immediate: true,
            handler() {
                if (this.superlazy != true) {
                    this.$nextTick(() => {
                        this.$store.state.lazy.update()
                    })
                }
            }
        }
    }
}
</script>