<template>
    <video muted
           loop
           ref="video"
           playsinline
           :poster="video.poster.poster">
        <source v-if="video.webm"
                :src="video.webm"
                type="video/webm" />

        <source :src="video.h264"
                type="video/mp4" />
    </video>
</template>

<style scoped>
video {
    display: block;
}
</style>

<script>
export default {
    props: ["video", "visible"],
    mounted() {
    },
    data() {
        return {
            loaded: false
        }
    },

    watch: {
        visible: {
            handler() {
                console.log("watcher", this.visible)
                if (this.visible) {
                    this.$nextTick(() => {
                        this.$refs.video?.play()
                    })

                }
            },
            immediate: true
        }
    }
}
</script>