<template>
    <div id="header">
        <div class="maxWidth">
            <router-link to="/about"
                         id="nameLink">
                <h1 id="name"
                    class="whiteBox"
                    v-html="$store.state.info.title" />
            </router-link>
        </div>

    </div>

    <div id="homeContent">
        <div id="grid">
            <template v-for="project in $store.state.projects">
                <SingleProjectLink :project="project"
                                   sizes="250px" />
            </template>
        </div>
    </div>

    <div id="overlay"
         :class="$route.name != 'Home' ? 'active' : ''">
        <router-view :key="this.$route.params.project" />
    </div>
</template>

<style lang="scss">
#header {
    position: fixed;
    top: 29px;
    z-index: 100;
    width: 100%;
}

#name {
    /* all: unset; */
    margin: unset;
    font-size: unset;
    font-weight: unset;
}

.whiteBox {
    background-color: white;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 8px;
    color: black;
    text-decoration: none!important;
}

#homeContent {
    padding: 103px 0px;
}


#grid {
    display: grid;
    width: 1390px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 130px;
    margin: 0px auto;

    a {
        min-width: 0px;
    }
}

.maxWidth {
    margin: 0px auto;
    width: 1390px;
}

@media screen and (max-width: (5*$maxGap + 4*$imageWidth)) {
    .maxWidth {
        width: calc(#{4*$imageWidth} + 3 * ((100vw - #{4*$imageWidth}) / 5));
    }

    #grid {
        width: unset;
        gap: calc((100vw - #{4*$imageWidth}) / 5);
        margin: 0px calc((100vw - #{4*$imageWidth}) / 5);
    }
}

@media screen and (max-width: (5*$minGap + 4*$imageWidth)) {
    .maxWidth {
        width: calc(#{3*$imageWidth} + 2 * ((100vw - #{3*$imageWidth}) / 4));
    }

    #grid {
        grid-template-columns: 1fr 1fr 1fr;
        width: unset;
        gap: calc((100vw - #{3*$imageWidth}) / 4);
        margin: 0px calc((100vw - #{3*$imageWidth}) / 4);
    }
}

@media screen and (max-width: (4*$minGap + 3*$imageWidth)) {
    .maxWidth {
        width: calc(#{2*$imageWidth} + 1 * ((100vw - #{2*$imageWidth}) / 3));
    }

    #grid {
        grid-template-columns: 1fr 1fr;
        width: unset;
        gap: calc((100vw - #{2*$imageWidth}) / 3);
        margin: 0px calc((100vw - #{2*$imageWidth}) / 3);
    }

    #header {
        text-align: center;
    }
}

@media screen and (max-width: (3*$minGap + 2*$imageWidth)) {
    #grid {
        grid-template-columns: 1fr 1fr;
        width: unset;
        gap: 30px;
        margin: 0px 30px;
    }

    .maxWidth {
        width: 100%;
        padding: 0px 30px;
        box-sizing: border-box;
    }
}

#intro {
    margin-bottom: 50px;
}

#timeMachineIntro {
    width: 100%;
    max-width: 800px;
    margin: 0px auto;
}


#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: rgba(255, 255, 255, 0.3);
    opacity: 0;
    backdrop-filter: blur(0px);
    pointer-events: none;
    transition: all 500ms;
    // overflow: auto;

    &.active {
        opacity: 1;
        backdrop-filter: blur(50px);
        pointer-events: all;
    }
}
</style>

<script>

import SingleProjectLink from '../components/SingleProjectLink.vue'
import TimeMachine from '../components/TimeMachine.vue';
export default {
    components: { SingleProjectLink, TimeMachine },
    data() {
        return {
            projects: []
        }
    },
    mounted() {
        if (document.querySelector("#homeData"))
            this.$store.commit("setProjects", JSON.parse(document.querySelector("#homeData").innerHTML))
        else {
            var q = {
                "query": "page(\'projects\').children",
                "select": {
                    "title": true,
                    "seo": true,
                    "slug": true,
                    "description": "page.description.kt",
                    "year": true,
                    "images": {
                        "query": "page.project_images.toFiles",
                        "select": {
                            "imagedata": true
                        }
                    }
                }
            }
            this.$query(q).then(r => {
                // console.log(r)
                this.$store.commit("setProjects", r.data.result
                )
            })
        }
    },
    computed: {
        slug() {
            return this.$route.params?.project;
        },
        images() {
            return this.$store.state.projects.map(p => {
                return p.images[0].imagedata;
            })
        }
    },
    watch: {
        projects() {
        }
    }
}
</script>