<template>
    <div class="timeMachineCont"
         :style="maxw">
        <div :class="{ 'timeMachine': true, looping }"
             :style="pad">
            <template v-for="(content, i) in loopedImages">
                <div class="imageContainer"
                     :style="genStyleCont(content, content.index)"
                     @click="forward"
                     ref="contentContainer"
                     :key="content.id"
                     :data-key="content.id"
                     v-if="realPlace(content.index) < 4 && realPlace(content.index) > -2">
                    <Image v-if="content.type == 'image'"
                           :img="content"
                           :style="genStyleImg(content)"
                           :sizes="sizes"
                           :superlazy="Math.abs(realPlace(content.index)) > 2"
                           :data-test="realPlace(content.index)"
                           :data-superlazy="content.index != currImage" />
                    <Video v-else
                           :video="content"
                           :style="genStyleImg(content)"
                           :visible="content.index == currImage" />
                    <div class="colorOverlay"
                         :style="genOpacity(i)">
                    </div>
                </div>
            </template>

        </div>
        <div class="textInfo">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">
.timeMachineCont {
    width: 100%;
    position: relative;
}

.timeMachine {
    position: relative;
    height: 0;
    padding-bottom: 120%;
    width: 100%;
    // aspect-ratio: 4 / 5;
    // max-width: 100%;
    max-height: 100%;
}

.looping {
    .imageContainer {
        transition: all 0ms;
    }
}

.imageContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    transition: all 600ms;
    cursor: pointer;

    img,
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .colorOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: white;
        z-index: 10;
        transition: opacity 600ms;
    }
}

.textInfo {
    position: absolute;
    width: 100%;
    bottom: 0px;
    transform: translateY(100%);

    a {
        color: black;
    }
}

@media screen and (max-width: 600px) {
    .timeMachineCont {
        max-width: unset !important;
    }
}
</style>

<script>
import Image from './Image.vue';
import Video from './Video.vue';
export default {
    components: { Image, Video },
    props: ["images", "sizes", "navigatable", "aspect"],
    data() {
        return {
            currImage: 0,
            looping: false
        }
    },
    mounted() {
        if (this.navigatable)
            console.log(this.images)
    },
    computed: {
        cursorStyle() {
            if (this._navigatable != undefined && this._navigatable != false) {
                return { cursor: "pointer" }
            }
        },
        noVideos() {
            return this.images;
        },
        loopedImages() {
            if (this.images.length > 1 && this.images.length < 5) {
                return [
                    ...this.images, ...this.images, ...this.images
                ].map((i, n) => ({ ...i, index: n }))
            }
            else {
                return this.images.map((i, n) => ({ ...i, index: n }))
            }

        },
        _navigatable() {
            return this.images.length == 1 ? false : this.navigatable
        },
        maxw() {
            return {
                maxWidth: "calc((100vh - 250px) * " + (1 / this.aspect) + ")"
            }
        },
        pad() {
            return {
                paddingBottom: (this.aspect * 100) + "%"
            }
        }

    },
    methods: {
        forward() {
            if (this._navigatable) {
                this.currImage++;
                if (this.currImage >= this.loopedImages.length) {
                    setTimeout(() => {
                        this.looping = true;
                        this.$nextTick(() => {
                            this.currImage %= this.loopedImages.length
                            setTimeout(() => {
                                this.looping = false
                            }, 50)
                        })
                    }, 600)
                }
            }
        },
        genStyleCont(img, i) {
            // console.log(img)
            // this.realPlace(i)

            var currentPosition = this.realPlace(i) * (this.realPlace(i) < 0 ? 3 : 1);
            return {
                cursor: (this._navigatable != undefined && this._navigatable != false) ? "pointer" : "default",
                pointerEvents: this.realPlace(i) == 0 ? "all" : "none",
                zIndex: 20 - this.realPlace(i),
                transform: "translateY(" + (-1) * (currentPosition * 5.5) + "%) scale(" + (1 - currentPosition / 17) + ")",
                opacity: this.realPlace(i) < 0 || this.realPlace(i) > 2 ? 0 : 1,
                backgroundColor: img.bgColor?.includes("#") || img.bgColor?.includes("rgb") ? img.bgColor : "#000000"
            }
        },
        genStyleImg(i) {
            return {
                objectFit: i.cover == "true" ? "cover" : "contain"
            }
        },
        genOpacity(i) {
            return {
                opacity: this.realPlace(i) / 3
            }
        },
        realPlace(i) {
            var currentPlace = (i - this.currImage)
            if (currentPlace < -1) currentPlace += this.loopedImages.length
            return currentPlace
        }
    }
}
</script>